import React from "react"
import { GlobalSection, GlobalContainer, Button, ButtonPlain } from "../../components/GlobalStyle"
import styled from "styled-components"
import SEO from "../../components/seo"
import PageHeader from "../../components/PageHeader"

import headerImage from "../../images/slider/slide-2.jpg"
import cowork from "../../images/graphics/cowork.svg"
import conference from "../../images/graphics/conference.svg"
import office from "../../images/graphics/office.svg"
import wifi from "../../images/icons/wifi.svg"
import printer from "../../images/icons/printer.svg"
import coffee from "../../images/icons/coffee-cup.svg"
import SectionContact from "../../components/sectionContact"
import {useTranslation} from 'gatsby-plugin-react-i18next';
const StyledOfferItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ inverted }) => inverted && `
    flex-direction: row-reverse;
    margin: 200px 0;
  `}
`
const StyledOfferItemActions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;
  a:first-of-type {
    margin-right: 30px;
  }
  @media (max-width: 1200px) {
    justify-content: center;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    a:first-of-type {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
`
const StyledOfferItemText = styled.div`
  flex: 1;
  padding-right: 50px;
  max-width: 680px;
  ${({ inverted }) => inverted && `
    padding-right: 0;
    padding-left: 50px;
  `}
  @media (max-width: 1200px) {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  @media (max-width: 1200px) {
    text-align: center;
  }
`
const StyledOfferItemImage = styled.div`
  width: 50%;
  text-align: right;
  img {
    width: 100%
  }
  @media (max-width: 1200px) {
    display: none;
  }
`
const StyledOfferItemDetails = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  @media (max-width: 1200px) {
    justify-content: center;
  }
  @media (max-width: 600px) {
    display: none;
  }
`
const StyledOfferItemDetailsIcon = styled.div`
  height: 48px;
  width: 48px;
  margin-right: 40px;
`
const StyledOfferItemDetailsPrice = styled.div`
  font-size: 3em;
  font-weight: 800;
  span {
    font-size: 16px;
  }
`
const StyledOfferItemName = styled.div`
  font-size: 3em;
  line-height: 1em;
  margin-bottom: 15px;
  @media (max-width: 1300px) {
    font-size: 2.6em;
  }
  @media (max-width: 600px) {
    font-size: 2em;
  }
  strong {
    display: block;
  }
  &:after {
    content: '';
    display: block;
    height: 2px;
    width: 150px;
    background-color: var(--main);
    margin-top: 15px;
    @media (max-width: 1200px) {
      transform: translateX(-50%);
      position: relative;
      left: 50%;
      margin: 30px 0;
    }
  }
`
const StyledOfferItemDescription = styled.div`
  display: flex;
  @media (max-width: 600px) {
    font-size: .9em;
  }
`

const ReservationPage = () => {
	const { t } = useTranslation()
	return ( <>
    <SEO
      title="Oferta"
      description="Leśna Hub to przestrzeń oferująca strefę coworkingowa, sale szkoleniowe i wirtualne biuro"
    />
    <PageHeader image={headerImage}>
      <h1 data-sal="slide-up" data-sal-delay="400" data-sal-duration="400">{t('header.offerTitle')}</h1>
      <p data-sal="slide-up" data-sal-delay="500" data-sal-duration="400">{t('header.offerText1')}</p>
      <p data-sal="slide-up" data-sal-delay="500" data-sal-duration="400">{t('header.offerText2')}</p>
    </PageHeader>
    <GlobalSection>
      <GlobalContainer>

        <StyledOfferItem>
          <StyledOfferItemText>
            <StyledOfferItemName>{t('offerCoworking.title1')}<strong>{t('offerCoworking.title2')}</strong></StyledOfferItemName>
            <StyledOfferItemDescription>{t('offerCoworking.text')}</StyledOfferItemDescription>
            <StyledOfferItemDetails>
              <StyledOfferItemDetailsIcon>
                <img src={ wifi } alt="ikona wifi"/>
              </StyledOfferItemDetailsIcon>
              <StyledOfferItemDetailsIcon>
                <img src={ printer } alt="ikona drukarki"/>
              </StyledOfferItemDetailsIcon>
              <StyledOfferItemDetailsIcon>
                <img src={ coffee } alt="ikona kubka kawy"/>
              </StyledOfferItemDetailsIcon>
              <StyledOfferItemDetailsPrice>
							{t('common.from')} 50zł
                <span>/{t('common.day')}</span>
              </StyledOfferItemDetailsPrice>
            </StyledOfferItemDetails>
            <StyledOfferItemActions>
              <Button to="/rezerwacja">{t('common.reservation')}</Button>
              <ButtonPlain to="/oferta/coworking">{t('common.readMore')}</ButtonPlain>
            </StyledOfferItemActions>
          </StyledOfferItemText>
          <StyledOfferItemImage>
            <img src={ cowork } alt="grafika strefy coworkingowej"/>
          </StyledOfferItemImage>
        </StyledOfferItem>

        <StyledOfferItem inverted>
          <StyledOfferItemText inverted>
            <StyledOfferItemName>{t('offerTraingroom.title1')} <strong>{t('offerTraingroom.title2')}</strong></StyledOfferItemName>
            <StyledOfferItemDescription>{t('offerTraingroom.text')}</StyledOfferItemDescription>
            <StyledOfferItemDetails>
              <StyledOfferItemDetailsIcon>
                <img src={ wifi } alt="ikona wifi"/>
              </StyledOfferItemDetailsIcon>
              <StyledOfferItemDetailsIcon>
                <img src={ printer } alt="ikona drukarki"/>
              </StyledOfferItemDetailsIcon>
              <StyledOfferItemDetailsIcon>
                <img src={ coffee } alt="ikona kubka kawy"/>
              </StyledOfferItemDetailsIcon>
              <StyledOfferItemDetailsPrice>
							{t('common.from')} 50zł
                <span>/{t('common.day')}</span>
              </StyledOfferItemDetailsPrice>
            </StyledOfferItemDetails>
            <StyledOfferItemActions>
              <Button to="/rezerwacja">{t('common.reservation')}</Button>
              <ButtonPlain to="/oferta/sale-szkoleniowe">{t('common.readMore')}</ButtonPlain>
            </StyledOfferItemActions>
          </StyledOfferItemText>
          <StyledOfferItemImage>
            <img src={ conference } alt="grafika strefy coworkingowej"/>
          </StyledOfferItemImage>
        </StyledOfferItem>

        <StyledOfferItem>
          <StyledOfferItemText>
            <StyledOfferItemName>{t('virtualOffice.title1')} <strong>{t('virtualOffice.title2')}</strong></StyledOfferItemName>
            <StyledOfferItemDescription>{t('virtualOffice.text')}</StyledOfferItemDescription>
            <StyledOfferItemDetails>
              <StyledOfferItemDetailsIcon>
                <img src={ wifi } alt="ikona wifi"/>
              </StyledOfferItemDetailsIcon>
              <StyledOfferItemDetailsIcon>
                <img src={ printer } alt="ikona drukarki"/>
              </StyledOfferItemDetailsIcon>
              <StyledOfferItemDetailsIcon>
                <img src={ coffee } alt="ikona kubka kawy"/>
              </StyledOfferItemDetailsIcon>
              <StyledOfferItemDetailsPrice>
							{t('common.from')} 4zł
                <span>/{t('common.day')}</span>
              </StyledOfferItemDetailsPrice>
            </StyledOfferItemDetails>
            <StyledOfferItemActions>
              <Button to="/rezerwacja">{t('common.reservation')}</Button>
              <ButtonPlain to="/oferta/wirtualne-biuro">{t('common.readMore')}</ButtonPlain>
            </StyledOfferItemActions>
          </StyledOfferItemText>
          <StyledOfferItemImage>
            <img src={ office } alt="grafika strefy coworkingowej"/>
          </StyledOfferItemImage>
        </StyledOfferItem>
      </GlobalContainer>
    </GlobalSection>
    <SectionContact />
  </>
)}

export default ReservationPage
